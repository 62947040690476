import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { FilterValue } from '@classes/report/filter-value';
import { MetadataService } from '@services/metadata.service';

@Injectable()
// {reports: Report[], universes: Universe[]}
export class PensionResolver  {
  constructor(
    private uiSrv: UiService,
    private metadataSrv: MetadataService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FilterValue[]> {
    this.uiSrv.routeLoadingStateChanged.next(true);

    return this.metadataSrv.getValues(335)
      .pipe(
        tap({
          next: () => this.uiSrv.routeLoadingStateChanged.next(false)
        })
      );
  }
}
