import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { ReportService } from '@services/report.service';
import { ReportCategory } from '@classes/report/report-category';

@Injectable()
export class ReportCategoriesResolver  {
    constructor(
        private uiSrv: UiService,
        private reportSrv: ReportService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ReportCategory[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.reportSrv.getCategories(true)
            .pipe(
                tap(() => {
                    this.uiSrv.routeLoadingStateChanged.next(false);
                })
            );
    }
}
