import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { Mapping } from '@classes/mapping/mapping';
import { ReportService } from '@services/report.service';

@Injectable()
export class ReportStructuresResolver  {
    constructor(
        private uiSrv: UiService,
        private reportSrv: ReportService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Mapping> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.reportSrv.getStructure()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
