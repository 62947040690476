import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { ReportService } from '@services/report.service';
import { UniverseService } from '@services/universe.service';

import { Report } from '@classes/report/report';
import { Universe } from '@classes/report/universe';
import { ReportLayoutType } from '@enums/report-layout-type.enum';

@Injectable()
export class ReportsAdvancedResolver  {
    constructor(
        private uiSrv: UiService,
        private reportSrv: ReportService,
        private universeSrv: UniverseService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{reports: Report[], universes: Universe[]}> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return forkJoin([
            this.reportSrv.getForProfiles(ReportLayoutType.flexmonster),
            this.universeSrv.get()
        ]).pipe(
            map(([reports, universes]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return {reports, universes};
            })
        );
    }
}
