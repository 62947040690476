import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { MetadataService } from '@services/metadata.service';
import { UserTagService } from '@services/user-tag.service';

import { FilterValue } from '@classes/report/filter-value';
import { UserTag } from '@classes/parameters/user-tag';

@Injectable()
// { origins: FilterValue[], endMotifs: FilterValue[], functionalCodes: FilterValue[], eventTypes: FilterValue[], userTags: UserTag[] }
export class HiringPlanResolver  {
    constructor(
        private uiSrv: UiService,
        private metadataSrv: MetadataService,
        private userTagSrv: UserTagService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ endMotifs: FilterValue[], functionalCodes: FilterValue[], eventTypes: FilterValue[], userTags: UserTag[] }> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return forkJoin([
            // this.metadataSrv.getValues(259),
            this.metadataSrv.getValues(307),
            this.metadataSrv.getValues(514),
            this.metadataSrv.getValues(838),
            this.userTagSrv.get()
        ]).pipe(
            map(([endMotifs, functionalCodes, eventTypes, userTags]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return { endMotifs, functionalCodes, eventTypes, userTags };
            })
        );
    }
}
